body{
  padding: 0;
  margin: 0;
}

.alarm-list-left{
  background: #fff;
  max-width: 400px;
  border: 1px solid #DC3545;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  float: left;
  position: absolute;
  left: 0;
  z-index: 98;
  height: calc(100vh - 80px);
  overflow-y: auto;
}
.mini-border-keys{
  border: 1px solid #DC3545;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.nim{
  width: 50px;
  height: 50px;
  display: block;
  background: red;

}
.custom-modal{
  /*position: fixed !important;*/
  /*background: white !important;*/
  /*z-index: 999922 !important;*/

}
.full-map-main{
    height: calc(100% - 60px) !important;
  box-sizing: border-box;
}
.alarm-box{
  background-color: #c9f8c1;

  border-radius: 15px 15px 15px 15px;
  //width: 200px;
  height: 100%;
  padding: 10px;
  //margin-right: 20px;
  position: relative;
  h6{
    position: relative;
    z-index: 12;
    color: white;

  }
  h5{
    font-weight: bolder;
    color: white;

  }
  h4{
    font-weight: bold;
    color: white;
  }
  p{
    margin: 0;
    color: white;
  }
  .alarm-img{
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 70px;
    z-index: 0;
  }
}
.create-device{
  border: none;
  background: transparent;
  margin-top: 10px;
  margin-left: 20px;
  //border-radius: 50%;
  //width: 60px;
  //height: 60px;
  //background: cornflowerblue;
  //border: none;
  //position: absolute;
  //top: 50px;
  //right: 50px;
}
.mainNavbar-fh{
  height: 60px;
  width: 100%;
}
.mainNavbar{
  position: fixed;
  background: #89a0ff;
  height: 60px;
  width: 100%;
  z-index: 999;
}
.main-side{
  background: #89a0ff;
  position: fixed;
  //height: 100%;
  width: 420px;
  top: 60px;
  bottom: 0;
  left: 0;
  z-index: 999;
  .side-list{
    ul{
      list-style: none;
      li{
        margin-top: 30px;
        a{
          font-weight: bolder;
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
.device-list{
  .left{
    width: 420px;
  }
  .right{
    box-sizing: border-box;
    width: calc(100% - 420px);
    padding: 50px;
  }
}
.change-btn{
  img{
    width: 20px;
  }
}


.switch {
  width: 3.8rem;
  border-radius: 1.1rem;

  &:after {
    left: 5%;
    top: 10%;
    border-radius: 50%;
    width: 45%;
    height: 80%;
    background: #4F565D;
    transform: translateX(0);
  }

  &:checked:after {
    background: #F6F8FF;
    transform: translateX(100%);
  }

}
input[type='checkbox'],
input[type='radio'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2.1rem;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid #a0a0a0;
  background: #a0a0a0;
  transition: background .3s, border-color .3s, outline .2s;

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform .3s ease, opacity .2s;
  }

  &:checked {
    background-color:  #275EFE;
    border-color: #275EFE;
    transition: transform .6s cubic-bezier(.2, .85, .32, 1.2), opacity .3s;
  }



  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        border-color: #F6F8FF;
      }
    }
  }

  &:focus-visible {
    outline: 2px solid #F6F8FF;
    outline-offset: 4px;
  }

  &:not(.switch) {
    width: 2.1rem;
  }

  & + label {
    display: inline-block;
    cursor: pointer;
    font-size: 1.4rem;
    margin-left: .2em;
    vertical-align: top;
  }
}

input[type='checkbox'] {
  &:not(.switch) {
    border-radius: .7rem;

    &:after {
      border: 2px solid #000;
      height: 45%;
      width: 25%;
      border-top: 0;
      border-left: 0;
      left: 38%;
      top: 20%;
      transform: rotate(20deg);
    }

    &:disabled:not(:checked):after {
      border-color: #000;
    }

    &:checked:after {
      transform: rotate(43deg);
    }
  }

  &.switch {
    width: 3.8rem;
    border-radius: 1.1rem;

    &:after {
      left: 5%;
      top: 10%;
      border-radius: 50%;
      width: 45%; // 50% - <left>
      height: 80%; // 100% - (<top> * 2)
      background: #fff;
      transform: translateX(0);
    }

    &:checked:after {
      background: #F6F8FF;
      transform: translateX(100%);
    }

    &:disabled {
      &:not(:checked) {
        &:after {
          background-color: #8F8F8F;
        }
      }
    }
  }
}

